import { Match } from '@/types/match'
import { parseRouteParams } from '@/processes/parseRouteParams'
import { useRouter } from 'next/router'
import { PageType } from '@/types/pageType'
import { useMemo } from 'react'

export type UseMatchData = {
  loading: boolean
  error: boolean
  match: Match
  pageType: PageType
}

const useMatch = (): UseMatchData => {
  const {
    query: { slugs },
  } = useRouter()

  return useMemo(() => {
    const { pageType, params } = parseRouteParams(slugs)

    return {
      loading: Object.keys(params).length === 0,
      error: slugs === undefined,
      match: { params } as Match,
      pageType,
    }
  }, [slugs])
}

export default useMatch
